<script setup>
import EcButton from "@/components/Button.vue";
import { computed, onMounted, reactive, ref, toRaw } from "vue";
import { useStore } from "vuex";
import { getImageCode, submitContact } from "@/apis";
import { showToast } from "@/utils";

const formRef = ref();
const labelCol = { style: { width: "80px" } };
const formState = reactive({
  company: "",
  name: "",
  email: "",
  phone: "",
  content: "",
  code: "",
});
const rules = {
  company: [
    {
      required: true,
      message: "请输入您的公司",
      trigger: "blur",
    },
    {
      min: 1,
      max: 100,
      message: "公司名称长度不超过100个字符",
      trigger: "blur",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入您的姓名",
      trigger: "blur",
    },
    {
      min: 1,
      max: 10,
      message: "姓名长度不超过10个字符",
      trigger: "blur",
    },
  ],
  email: [
    {
      required: true,
      message: "请输入您的邮箱",
      trigger: "blur",
    },
    {
      min: 1,
      max: 100,
      message: "邮箱不超过100个字符",
      trigger: "blur",
    },
  ],
  phone: [
    {
      required: true,
      message: "请输入您的手机号",
      trigger: "blur",
    },
    {
      min: 11,
      max: 11,
      message: "手机号为11个字符",
      trigger: "blur",
    },
  ],
  content: [
    {
      required: true,
      message: "请输入您的留言内容",
      trigger: "blur",
    },
    {
      min: 1,
      max: 500,
      message: "留言内容长度为1-500个字符",
      trigger: "blur",
    },
  ],
  code: [
    {
      required: true,
      message: "请输入验证码",
      trigger: "blur",
    },
    {
      min: 4,
      max: 4,
      message: "验证码为4个字符",
      trigger: "blur",
    },
  ],
};
const codeImg = ref();
const loading = ref(false);
const store = useStore();

const appConfig = computed(() => store.state.config);

const onSubmit = () => {
  if (loading.value) return;
  loading.value = true;
  formRef.value
    .validate()
    .then(async () => {
      const data = toRaw(formState);
      const res = await submitContact({
        appId: appConfig.value.appId,
        name: data.name,
        company: data.company,
        contact: data.phone,
        remark: data.content,
        code: data.code,
      });
      if (res.code !== 0) {
        showToast(res);
        loading.value = false;
        return;
      }
      await showToast({ code: 0, msg: "提交成功!" });
      loading.value = false;
      document.documentElement.scrollTop = 0;
      window.history.go(0);
    })
    .catch((error) => {
      console.log("error", error);
      loading.value = false;
    });
};

// 获取验证码
const getCode = async () => {
  if (loading.value) return;
  loading.value = true;
  const res = await getImageCode({
    width: 115,
    height: 52,
    appId: appConfig.value.appId,
  });
  if (res.code !== 0) {
    showToast(res);
    loading.value = false;
    return;
  }
  codeImg.value = res.data;
  loading.value = false;
};

onMounted(() => {
  getCode();
});
</script>

<template>
  <div class="ec-contact-form-wrapper">
    <a-form
      class="ec-contact-form"
      ref="formRef"
      :model="formState"
      :rules="rules"
      :labelCol="labelCol"
    >
      <a-row class="ec-footer-form-row" :gutter="30">
        <a-col :span="24" class="ec-footer-form-col">
          <a-form-item ref="company" label="公司名称" name="company">
            <a-input
              autocomplete="off"
              placeholder="您的公司"
              v-model:value="formState.company"
            />
          </a-form-item>
        </a-col>
        <a-col :span="24" class="ec-footer-form-col">
          <a-form-item
            ref="name"
            label="姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名"
            name="name"
          >
            <a-input
              autocomplete="off"
              placeholder="您的姓名"
              v-model:value="formState.name"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="ec-footer-form-row" :gutter="30">
        <a-col :span="24" class="ec-footer-form-col">
          <a-form-item
            ref="phone"
            label="手&nbsp;&nbsp;机&nbsp;&nbsp;号"
            name="phone"
          >
            <a-input
              autocomplete="off"
              placeholder="手机号（已加密）"
              v-model:value="formState.phone"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="ec-footer-form-row">
        <a-col :span="24" class="ec-footer-form-col">
          <a-form-item ref="content" label="需求描述" name="content">
            <a-textarea
              autocomplete="off"
              placeholder="您的需求..."
              v-model:value="formState.content"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="ec-footer-form-row">
        <a-col :span="24" class="ec-footer-form-col">
          <a-form-item
            ref="code"
            label="验&nbsp;&nbsp;证&nbsp;&nbsp;码"
            name="code"
            :autoLink="false"
          >
            <div class="ec-contact-form-addon">
              <a-input
                autocomplete="off"
                placeholder="验证码"
                v-model:value="formState.code"
                @blur="
                  () => {
                    $refs.code.onFieldBlur();
                  }
                "
                @change="
                  () => {
                    $refs.code.onFieldChange();
                  }
                "
              />
              <a
                href="javascript: void(0);"
                class="ec-footer-code"
                @click="getCode"
              >
                <img :src="codeImg" alt="" />
              </a>
            </div>
          </a-form-item>
        </a-col>
      </a-row>

      <EcButton class="ec-contact-form-btn" @click="onSubmit"> 提 交 </EcButton>
    </a-form>
  </div>
</template>

<style lang="less" scoped>
.ec-contact-form-wrapper {
  :deep(.ec-contact-form) {
    .ant-form-item {
      margin-bottom: 24px;
    }
    .ant-form-item-control {
      width: 100%;
      max-width: 100%;

      input {
        height: 52px;
        line-height: 52px;
        width: 100%;
        padding: 0 25px;
        font-size: 14px;
        font-weight: 400;
        border-color: #ffffff;
        border-radius: 6px;
      }
      textarea {
        height: 109px;
        resize: none;
        padding: 12px 25px;
        font-size: 14px;
        font-weight: 400;
        border-color: #ffffff;
        border-radius: 6px;
      }
    }
    .ant-form-item-explain {
      //opacity: 1;
      position: absolute;
      z-index: 1000;
      left: 0;
      bottom: -24px;
    }
    .ant-form-item-label label {
      height: 100%;
    }
    .ec-contact-form-btn {
      width: 100%;
    }
    .ec-contact-form-addon {
      position: relative;

      :deep(input.ant-input) {
        padding-right: 153px !important;
      }

      .ec-footer-code {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 1px;
        width: 117px;
        height: 100%;
      }
    }
  }
}
</style>
