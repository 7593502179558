<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { heightToTop } from "@/utils";
import { useRoute, useRouter } from "vue-router";
import EcButton from "@/components/Button.vue";
import ContactModal from "@/components/Contact/Modal.vue";

const navs = [
  { key: "1", na: "首页", link: "/", id: "ecHome" },
  { key: "2", na: "解决方案", link: "/", id: "ecHomeCompose" },
  { key: "3", na: "关于我们", link: "/about", id: "ecAbout" },
  { key: "4", na: "联系我们", link: "/", id: "ecFooter" },
];

const route = useRoute();
const router = useRouter();

const active = ref(navs[0].key);
const navRef = ref(null);
const activeWidth = ref(0);
const activeLeft = ref(45);
const scrollTop = ref(0);
const contactVisible = ref(false);

const activeIndex = computed(() =>
  navs.findIndex((it) => it.key === active.value)
);

const onNav = ({ key, link, id }) => {
  active.value = key;
  router.push(link);
  if (id) {
    setTimeout(() => {
      const target = document.getElementById(id);
      document.documentElement.scrollTop = heightToTop(target) - 100;
    }, 0);
  }
};

const onScroll = () => {
  const sTop = document.body.scrollTop + document.documentElement.scrollTop;
  scrollTop.value = sTop;
  if (!navRef.value) return;
  if (sTop > 92) {
    navRef.value.classList.add("fixed");
    return;
  }
  navRef.value.classList.remove("fixed");
};

const onMenu = (item) => {
  if (item.top !== void 0) {
    document.documentElement.scrollTop = item.top;
    return;
  }
  if (item.id) {
    const ele = document.getElementById(item.id);
    if (!ele) return;
    const height = heightToTop(ele);
    document.documentElement.scrollTop = height - 129;
  }
};

const onMouseOver = (el) => {
  const styles = getComputedStyle(el);
  const width = parseFloat(styles.width) || 0;
  activeWidth.value = width - 60;
  activeLeft.value = el.offsetLeft + 30;
};

const onMouseLeave = () => {
  const current = document.querySelector(
    `.ec-main-header-nav-item-${activeIndex.value}`
  );
  onMouseOver(current);
};

const onSignIn = () => {
  window.location.href = "https://ehr.hrmany.com/user/sign-in";
};

const onSignUp = () => {
  contactVisible.value = true;
};

onMounted(() => {
  onScroll();
  document.addEventListener("scroll", onScroll);

  const currentPath = window.location.pathname;
  let currentIndex = navs.findIndex((it) => {
    if (it.link === "/") return it.link === currentPath;
    return new RegExp("^" + it.link).test(currentPath);
  });
  if (currentIndex < 0) currentIndex = 0;
  const current = document.querySelector(
    `.ec-main-header-nav-item-${currentIndex}`
  );
  onMouseOver(current);
});

onUnmounted(() => {
  document.removeEventListener("scroll", onScroll);
});
</script>

<template>
  <header
    class="ec-main-header"
    :class="`route${route.path.replace(/\//g, '-')}`"
  >
    <nav class="ec-main-header-nav" ref="navRef">
      <div class="ec-main-header-nav-inner ec-container">
        <h1 title="思博众合" class="ec-main-header-logo">
          <img src="@/assets/images/logo.png" alt="思博众合" />
        </h1>
        <ul class="ec-main-header-nav-list" @mouseleave="() => onMouseLeave()">
          <li
            class="ec-main-header-nav-item"
            :class="[
              active === item.key ? 'active' : '',
              `ec-main-header-nav-item-${index}`,
            ]"
            v-for="(item, index) in navs"
            :key="item.key"
            @click="onMenu(item)"
            @mouseover="(e) => onMouseOver(e.currentTarget)"
          >
            <a href="javascript: void(0);" @click="onNav(item)">
              {{ item.na }}
            </a>
          </li>
          <li
            class="ec-main-header-nav-pointer"
            :style="{
              width: `${activeWidth}px`,
              transform: `translateX(${activeLeft}px)`,
            }"
          />
        </ul>
        <ul class="ec-main-header-contact-list">
          <li class="ec-main-header-contact">
            <i class="ec-main-header-contact-icon"></i>
            <a class="ec-main-header-contact-phone" href="tel:400-8005-073">
              400-8005-073
            </a>
          </li>
          <li class="ec-main-header-account">
            <EcButton
              class="ec-main-header-account-link sign-in"
              @click="onSignIn"
            >
              登录
            </EcButton>
            <EcButton
              class="ec-main-header-account-link sign-up"
              @click="onSignUp"
            >
              注册
            </EcButton>
          </li>
        </ul>
      </div>
    </nav>
    <ContactModal v-model:visible="contactVisible" />
  </header>
</template>

<style lang="less" scoped>
.ec-main-header {
  width: 100%;
  height: 92px;
  min-width: max-content;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;

  &.route-about {
    background: #ffffff;
  }

  &-logo {
    width: 154px;
    height: 34px;
    margin-right: 22px;

    img {
      display: block;
      outline: none;
      border: 0;
      height: 100%;
    }
  }

  &-nav {
    height: 100%;

    &.fixed {
      box-shadow: 0 2px 4px 0 rgba(104, 119, 174, 0.08);
      background: rgba(255, 255, 255, 1);
    }
  }
  &-nav-inner {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }

  &-nav-list {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
  &-nav-item {
    height: 100%;
    padding: 0 32px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      font-size: 16px;
      font-weight: normal;
      color: #2f2f2f;
    }
  }
  &-nav-pointer {
    display: block;
    height: 4px;
    border-radius: 2px;
    background: @ec-color-primary;
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 27px;
    transition: all 0.3s ease-in-out;
  }
  &-contact-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    gap: 40px;
    margin-left: auto;
  }
  &-contact {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    gap: 6px;
  }
  &-contact-icon {
    display: block;
    width: 21px;
    height: 21px;
    background: url("@/assets/images/home/tell.png") no-repeat center / cover;
  }
  &-contact-phone {
    font-size: 20px;
    font-weight: 400;
    color: #696969;
  }
  &-account {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    gap: 9px;
  }
  :deep(&-account-link) {
    height: 28px;
    line-height: 26px;
    border: 1px solid @ec-color-primary;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    color: #4ab79d;
    padding: 0 11px;
    background: #ffffff;

    &:focus,
    &:hover {
      background: #ffffff;
    }

    &.sign-up {
      background: @ec-color-primary;
      color: #ffffff;
    }
  }
}
</style>
