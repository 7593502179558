<script setup></script>

<template>
  <h2 class="ec-section-title">
    <slot></slot>
  </h2>
</template>

<style scoped lang="less">
.ec-section-title {
  font-size: 32px;
  font-weight: 400;
  color: #2f2f2f;
  text-align: center;
}
</style>
