<script setup>
import EcButton from "@/components/Button";
import SectionTitle from "@/components/SectionTitle";
import { heightToTop } from "@/utils";
import ProItem from "./ProItem.vue";

const onFree = () => {
  const target = document.getElementById("ecFooter");
  document.documentElement.scrollTop = heightToTop(target);
};
</script>

<template>
  <div class="ec-home" id="ecHome">
    <section class="ec-home-header">
      <div class="ec-home-header-inner ec-container">
        <div class="ec-home-header-left">
          <h2 class="ec-home-header-title">服务为先 赋能为本</h2>
          <h3 class="ec-home-header-title sub">
            <p>为企业提供基于 Easy Work 的人资服务快捷体验应用平台</p>
          </h3>
          <EcButton class="ec-home-header-btn" @click="onFree">
            联系我们
          </EcButton>
        </div>
        <div class="ec-home-header-right"></div>
      </div>
    </section>
    <section class="ec-home-service">
      <div class="ec-home-service-inner ec-container">
        <SectionTitle class="ec-home-section-title">
          “灵动”一体化服务集成+模块化
        </SectionTitle>
        <img
          class="ec-home-service-img"
          src="@/assets/images/home/img01.png"
          alt=""
        />
      </div>
    </section>
    <section class="ec-home-ecology">
      <div class="ec-home-ecology-inner ec-container">
        <SectionTitle class="ec-home-section-title">
          携手众多专业伙伴打造<strong>人力资源服务生态链</strong>
        </SectionTitle>
        <div class="ec-home-ecology-content">
          <div class="ec-home-ecology-left">
            <h3 class="ec-home-ecology-title">“互联网+资源整合”模式</h3>
            <div class="ec-home-ecology-profile">
              <p>思博众合创新地提出“互联网+资源整合”模式</p>
              <p>与企业共同构建创新、融合的人力资源策略</p>
            </div>
          </div>
          <div class="ec-home-ecology-right">
            <img
              class="ec-home-ecology-img"
              src="@/assets/images/home/img02.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
    <section class="ec-home-pro">
      <div class="ec-home-pro-inner ec-container">
        <SectionTitle class="ec-home-section-title">
          快速连接人力资源专业服务
        </SectionTitle>
        <ul class="ec-home-pro-content">
          <ProItem
            title="需求触发"
            icon="sever-ico01.png"
            :features="['初步需求/试用申请', '顾问快速对接']"
          />
          <ProItem
            title="需求梳理"
            icon="sever-ico02.png"
            :features="['了解应用场景', '理清核心需求']"
          />
          <ProItem
            title="方案建议/选择试用"
            icon="sever-ico03.png"
            :features="['方案选择', '免费体验/开通试用账号']"
          />
          <ProItem
            title="启动服务"
            icon="sever-ico04.png"
            :features="['开通账号（线上项目）', '项目启动（线下项目）']"
          />
        </ul>
      </div>
    </section>
    <section class="ec-home-compose" id="ecHomeCompose">
      <div class="ec-home-compose-inner ec-container">
        <SectionTitle class="ec-home-section-title">
          所得即所需，服务模块<strong>随需组合</strong>
        </SectionTitle>
        <h3 class="ec-home-compose-title-sub">
          一个链接触达所有模块服务，一个账号实现服务集成
        </h3>
      </div>
    </section>
    <section class="ec-home-customer">
      <div class="ec-home-customer-inner ec-container">
        <SectionTitle class="ec-home-section-title">合作伙伴</SectionTitle>
        <img
          class="ec-home-customer-img"
          src="@/assets/images/home/partners-logo.png"
          alt=""
        />
      </div>
    </section>
  </div>
</template>

<style lang="less" scoped>
.ec-home {
  &-header {
    background: url("@/assets/images/home/banner.png") no-repeat center /
      contain;
    height: 618px;
    min-width: max-content;

    .ec-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    &-left {
    }
    &-title {
      font-size: 38px;
      font-weight: 500;
      color: #2f2f2f;
      line-height: 57px;
      margin-top: 219px;

      &.sub {
        font-size: 20px;
        font-weight: normal;
        color: #525252;
        line-height: 44px;
        margin-top: 35px;
      }
    }
    &-btn {
      width: 145px;
      height: 46px;
      margin-top: 34px;
    }
  }
  &-service {
    margin-top: 67px;

    &-img {
      display: block;
      width: 891px;
      height: 697px;
      margin: 83px auto 0;
    }
  }
  &-ecology {
    margin-top: 156px;

    &-content {
      margin-top: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-left {
    }
    &-right {
    }
    &-title {
      font-size: 24px;
      font-weight: bold;
      color: #2f2f2f;
      line-height: 42px;
    }
    &-profile {
      font-size: 18px;
      font-weight: normal;
      color: #616366;
      line-height: 42px;
      margin-top: 27px;
    }
    &-img {
      width: 512px;
      height: 431px;
    }
  }
  &-pro {
    margin-top: 84px;
    background: #f5f6fa;
    padding: 82px 0 102px;

    &-content {
      margin-top: 86px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &-compose {
    height: 700px;
    padding-top: 83px;
    background: url("@/assets/images/home/integration-bg.png") no-repeat center /
      cover;
    min-width: max-content;

    .ec-home-section-title {
      color: #ffffff;
    }

    &-title-sub {
      font-size: 26px;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
    }
  }
  &-customer {
    padding-top: 74px;
    padding-bottom: 49px;
    background: #f5f6fa;

    &-img {
      width: 100%;
      margin-top: 54px;
    }
  }
}
</style>
