<script setup>
import { ref, watch } from "vue";
import Index from "./Index.vue";

const props = defineProps({
  visible: Boolean,
});
const emits = defineEmits(["update:visible"]);

const visibleInner = ref(props.visible);

const onClose = () => {
  emits("update:visible", false);
};

watch(props, (_props) => {
  visibleInner.value = _props.visible;
});
</script>

<template>
  <a-modal
    class="ec-contact-modal"
    :width="940"
    v-model:visible="visibleInner"
    @cancel="onClose"
    :maskClosable="false"
    :footer="null"
    destroyOnClose
  >
    <Index ref="indexRef" />
  </a-modal>
</template>

<style lang="less">
.ec-contact-modal {
  .ant-modal-body {
    padding: 0;
  }
}
</style>
