<script setup>
import { ref } from "vue";

const props = defineProps({
  title: {
    type: String,
  },
  features: {
    type: Array,
  },
  icon: {
    type: String,
  },
});

const img = ref(null);
import(`../../assets/images/home/${props.icon}`).then((res) => {
  try {
    img.value = res.default;
  } catch (err) {
    console.error(err);
  }
});
</script>

<template>
  <li class="ec-home-pro-item">
    <img class="ec-home-pro-icon" :src="img" alt="" v-if="img" />
    <div class="ec-home-pro-icon" v-else></div>
    <h4 class="ec-home-pro-title">{{ title }}</h4>
    <ul class="ec-home-pro-features">
      <li class="ec-home-pro-feature" v-for="(it, idx) in features" :key="idx">
        {{ it }}
      </li>
    </ul>
  </li>
</template>

<style lang="less" scoped>
.ec-home-pro {
  &-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    width: 302px;
    height: 423px;
    box-shadow: 0 0 9px 1px rgba(221, 222, 227, 0.13);
    border-radius: 10px;
    transition: all 0.3s linear;

    &:hover {
      transform: translate(1px, -10px);
      box-shadow: 0 2px 18px 1px rgba(221, 222, 227, 0.3);
    }
  }
  &-icon {
    display: block;
    width: 96px;
    height: 90px;
    margin-top: 49px;
  }
  &-title {
    font-size: 24px;
    font-weight: 400;
    color: #2f2f2f;
    margin-top: 30px;
    line-height: 1;
    cursor: default;
  }
  &-features {
    font-size: 16px;
    font-weight: 400;
    color: #616366;
    line-height: 30px;
    margin-top: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  &-feature {
    list-style: disc;
    cursor: default;
  }
}
</style>
